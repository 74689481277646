import {
  Navigate,
  Route,
  Routes,
  createRoutesFromElements,
  createBrowserRouter,
} from 'react-router-dom';
import { lazy } from 'react';
import NavBar from 'module/common/NavBar/NavBar';
import { PrivateRoute } from './PrivateRoute';
import { RestrictedRoute } from './RestrictedRoute';

const ProjectsPage = lazy(() => import('pages/ProjectsPage/ProjectsPage'));
const WidgetsPage = lazy(() => import('pages/WidgetsPage/WidgetsPage'));
const AirtimesPage = lazy(() =>
  import('pages/Airtime/AirtimesPage/AirtimesPage')
);
const AirtimeItemsPage = lazy(() =>
  import('pages/Airtime/AirtimeItemsPage/AirtimeItemsPage')
);
const ContentsPage = lazy(() =>
  import('pages/Media/ContentsPage/ContentsPage')
);
const MediaPage = lazy(() => import('pages/Media/MediaPage/MediaPage'));
const TagsPage = lazy(() => import('pages/Media/TagsPage/TagsPage'));
const UsersPage = lazy(() => import('pages/UsersPage/UsersPage'));
const LoginPage = lazy(() => import('pages/LoginPage/LoginPage'));
const GroupPage = lazy(() => import('pages/Devices/GroupPage/GroupPage'));
const DevicesGroupsPage = lazy(() =>
  import('pages/Devices/DevicesGroupsPage/DevicesGroupsPage')
);
const DevicesAllPage = lazy(() =>
  import('pages/Devices/DevicesAllPage/DevicesAllPage')
);
const DevicesPage = lazy(() => import('pages/Devices/DevicesPage'));
const ClientsPage = lazy(() => import('pages/ClientsPage/ClientsPage'));
const ReportingPage = lazy(() => import('pages/ReportingPage/ReportingPage'));
const HotPlaylistsPage = lazy(() =>
  import('pages/Airtime/HotPlaylists/HotPlaylistsPage')
);
const HotPlaylistPage = lazy(() =>
  import('pages/Airtime/HotPlaylists/HotPlaylistPage/HotPlaylistPage')
);
const DevicePlaylistPage = lazy(() =>
  import('pages/Airtime/DevicePlaylistPage/DevicePlaylistPage')
);

const ProgrammersPage = lazy(() =>
  import('pages/Airtime/ProgrammersPage/ProgrammersPage')
);
const router = [
  <Route
    key="/"
    path="/"
    element={<PrivateRoute redirectTo="/login" component={NavBar} />}
  >
    <Route
      path=""
      element={<PrivateRoute redirectTo="/login" component={ProjectsPage} />}
    />
    <Route
      path=":projectId/groups"
      element={<PrivateRoute redirectTo="/login" component={DevicesPage} />}
    >
      <Route
        path=""
        element={
          <PrivateRoute redirectTo="/login" component={DevicesGroupsPage} />
        }
      />
      <Route
        path="devices"
        element={
          <PrivateRoute redirectTo="/login" component={DevicesAllPage} />
        }
      />
      <Route
        path=":id"
        element={<PrivateRoute redirectTo="/login" component={GroupPage} />}
      />
    </Route>
    <Route
      path=":projectId/media"
      element={<PrivateRoute redirectTo="/login" component={MediaPage} />}
    />
    <Route
      path=":projectId/media/:id"
      element={<PrivateRoute redirectTo="/login" component={ContentsPage} />}
    />
    <Route
      path=":projectId/media/tags"
      element={<PrivateRoute redirectTo="/login" component={TagsPage} />}
    />
    <Route
      path=":projectId/widgets"
      element={<PrivateRoute redirectTo="/login" component={WidgetsPage} />}
    />
    <Route
      path=":projectId/airtimes"
      element={<PrivateRoute redirectTo="/login" component={AirtimesPage} />}
    />
    <Route
      path=":projectId/airtimes/:id"
      element={
        <PrivateRoute redirectTo="/login" component={AirtimeItemsPage} />
      }
    />
    <Route
      path=":projectId/airtimes/:id/:deviceId"
      element={
        <PrivateRoute redirectTo="/login" component={DevicePlaylistPage} />
      }
    />
    <Route
      path=":projectId/airtimes/hot-playlists"
      element={
        <PrivateRoute redirectTo="/login" component={HotPlaylistsPage} />
      }
    />
    <Route
      path=":projectId/airtimes/hot-playlists/:id"
      element={<PrivateRoute redirectTo="/login" component={HotPlaylistPage} />}
    />
    <Route
      path=":projectId/airtimes/programmers"
      element={<PrivateRoute redirectTo="/login" component={ProgrammersPage} />}
    />

    <Route
      path=":projectId/clients"
      element={<PrivateRoute redirectTo="/login" component={ClientsPage} />}
    />
    <Route
      path=":projectId/reporting"
      element={<PrivateRoute redirectTo="/login" component={ReportingPage} />}
    />
    <Route
      path="users"
      element={<PrivateRoute redirectTo="/login" component={UsersPage} />}
    />
  </Route>,
  <Route
    path="login"
    key="login"
    element={<RestrictedRoute redirectTo="/" component={LoginPage} />}
  />,
  <Route key="*" path="*" element={<Navigate to="/" />} />,
];

export default router;
