import {
  RiComputerLine,
  RiFilmLine,
  RiLayoutMasonryLine,
  RiBroadcastLine,
  RiUser3Line,
  RiArticleLine,
  RiLayoutGridLine,
  RiGroupLine,
} from 'react-icons/ri';
import PropTypes from 'prop-types';
import Logo from 'shared/components/Logo/Logo';
import { Link, ListLink, LinkItem } from './NavMenu.styled';
import { useHasRoles } from 'shared/hooks/useHasRoles';
import { userRoles } from 'shared/utils/constants';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Line from 'shared/components/Line/Line';

export default function NavMenu({ onClose }) {
  const hasAdminRole = useHasRoles(userRoles.ADMIN);
  const hasUserRole = useHasRoles(userRoles.USER);
  const hasManagerRole = useHasRoles(userRoles.MANAGER);
  const hasSupportRole = useHasRoles(userRoles.SUPPORT);
  const hasPartnerRole = useHasRoles(userRoles.PARTNER);

  const { projectId } = useParams();
  const { t } = useTranslation();

  return (
    <div>
      <Logo
        sx={{
          paddingTop: '24px',
          paddingBottom: '24px',
        }}
      />
      <Line />
      <ListLink>
        <LinkItem onClick={onClose}>
          <Link to="/">
            <RiLayoutGridLine />
            <span>{t('projects.title')}</span>
          </Link>
        </LinkItem>
        {projectId && (
          <>
            {hasSupportRole && (
              <LinkItem onClick={onClose}>
                <Link to={`/${projectId}/groups`}>
                  <RiComputerLine />
                  <span>{t('devices.title')}</span>
                </Link>
              </LinkItem>
            )}
            {(hasPartnerRole || hasManagerRole) && (
              <LinkItem onClick={onClose}>
                <Link to={`${projectId}/media`}>
                  <RiFilmLine />
                  <span>{t('media.title')}</span>
                </Link>
              </LinkItem>
            )}
            {(hasUserRole || hasAdminRole) && (
              <LinkItem onClick={onClose}>
                <Link to={`${projectId}/widgets`}>
                  <RiLayoutMasonryLine />
                  <span>{t('widgets.title')}</span>
                </Link>
              </LinkItem>
            )}
            {hasPartnerRole && (
              <LinkItem onClick={onClose}>
                <Link to={`${projectId}/airtimes`}>
                  <RiBroadcastLine />
                  <span>{t('airtimes.title')}</span>
                </Link>
              </LinkItem>
            )}
            {hasManagerRole && (
              <>
                <LinkItem onClick={onClose}>
                  <Link to={`${projectId}/clients`}>
                    <RiUser3Line />
                    <span>{t('clients.title')}</span>
                  </Link>
                </LinkItem>
                <LinkItem onClick={onClose}>
                  <Link to={`${projectId}/reporting`}>
                    <RiArticleLine />
                    <span>{t('reporting.title')}</span>
                  </Link>
                </LinkItem>
              </>
            )}
          </>
        )}

        {hasAdminRole && (
          <LinkItem onClick={onClose}>
            <Link to="/users">
              <RiGroupLine />
              <span>{t('users.title')}</span>
            </Link>
          </LinkItem>
        )}
      </ListLink>
      <Line />
    </div>
  );
}

NavMenu.propTypes = {
  onClose: PropTypes.func,
};
